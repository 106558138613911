<template>
  <base-page-layout title="Dashboard Implantação">
    <!-- utilities -->
    <v-card flat class="my-4">
      <h2 class="mx-4 py-4"><v-icon class="mr-2 mb-1">mdi-cog</v-icon> Utilidades</h2>
      <deployment-utilities />
    </v-card>

    <!-- reports -->
    <v-card flat class="my-4">
      <h2 class="mx-4 py-4"><v-icon class="mr-2 mb-1">mdi-chart-bar</v-icon> Relatórios</h2>
      <deployment-reports />
    </v-card>
  </base-page-layout>
</template>

<script>
export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout'),
    DeploymentUtilities: () => import('@/modules/deployment/components/DeploymentUtilities'),
    DeploymentReports: () => import('@/modules/deployment/components/DeploymentReports')
  }
}
</script>
